import { IStackTokens, Label, Separator, Stack, StackItem, ThemeProvider } from '@fluentui/react';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import { TopNav } from './elements/topnav';
import { baseUrl, thisIsDevEnvironment } from './lib/helper';
import Consent from './pages/consent';
import Contact from './pages/contact';
import FAQ from './pages/faq';
import FreqLinks from './pages/freqlinks';
import Home from './pages/home';
import NotFound from './pages/notfound';
import PartsFRU from './pages/partsfru';
import Products from './pages/product';
import Research from './pages/research';
import SessionExpired from './pages/sessionExpired';
import SFTP from './pages/sftp';
import Shares from './pages/share';
import TermsOfUse from './pages/terms';
import TrainingVideos from './pages/trainingvideos';
import WhitePapers from './pages/whitepapers';
import { useAuthenticationContext } from './providers/authprovider';
import { useLoadContext } from './providers/loadProvider';
import { NavProvider } from './providers/navProvider';
import { ProductsProvider } from './providers/productsProvider';
import './styles/mainapp.scss';
import { AppTheme } from './styles/themeprovider';


//TODO: circle back on this for UAT, prod, etc.
const connectionString = thisIsDevEnvironment ? 'InstrumentationKey=18d5346c-6999-4e9f-9d22-c097f35353b8;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/' : '';

if (connectionString) {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: connectionString,
      enableAutoRouteTracking: true
    }
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

initializeIcons('https://res.cdn.office.net/files/fabric-cdn-prod_20240129.001/assets/icons/');
// Tokens definition for stack
const GapStackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10
};

const App: React.FunctionComponent = () => {
  const authStatus = useAuthenticationContext();
  const loadContext = useLoadContext();
  //CSS 3 animation uses several empty divs
  const emptyDivs = (length: number, fn: any) => Array.from({ length }, (_, i) => fn(i));
  return (
    <ThemeProvider theme={AppTheme}>
      <ProductsProvider>
        <Stack className={`appWrap ${loadContext.LoadingMessage ? 'loadOverlay' : ''}`}>
          <Stack className='loadMessage' horizontalAlign='center' tokens={GapStackTokens}>
            <div className="lds-roller">{emptyDivs(8, (i: React.Key) => <div key={i} />)}</div>
            <Label>{loadContext.LoadingMessage?.message}</Label>
          </Stack>
        </Stack>
        <Stack className='main'>
          <Stack className='loginBar' tokens={GapStackTokens} horizontalAlign='end' verticalAlign='center'>
            <div className='innerLoginBar'>
              <Stack horizontal verticalAlign='center'>
                {authStatus.Result?.IsAuthenticated ?
                  <>
                    <span title={authStatus.Result?.UserInfo?.Email}>{authStatus.Result?.UserInfo?.DisplayName}{authStatus.Result?.UserInfo?.CompanyName && ` - ${authStatus.Result.UserInfo.CompanyName}${authStatus.Result.IsAdmin ? " (Admin)" : ""}`}</span>
                    <DefaultButton className='contactButton' href={`${baseUrl}/logout?redirect=${window.location.origin}`} text='Log Out' /><DefaultButton className='contactButton' text='Contact' allowDisabledFocus title='Contact Us' href="/contact" />
                    {/* <DefaultButton className='contactButton' href={`${baseUrl}/logout`} text='Log Out' /><DefaultButton className='contactButton' text='Contact' allowDisabledFocus title='Contact Us' href="/contact" /> */}
                  </>
                  : <DefaultButton className='contactButton' href={`${baseUrl}/login/azuread?redirect=${window.location.pathname}`} text='Log In' />
                }

              </Stack>
            </div>
          </Stack>
          <Stack className='logoNavBox' horizontal tokens={GapStackTokens} horizontalAlign='start' verticalAlign='center'>
            <StackItem className='headStack'>
              <span><img src={`${process.env.PUBLIC_URL}/img/mLogo.png`} className='mLogo' alt='Meteorcomm Logo' /></span>
              <span><div className='App-title'>Customer Partner Portal</div></span>
            </StackItem>
            <Stack enableScopedSelectors className='topNavBox'>
              <NavProvider>
                <TopNav />
              </NavProvider>
            </Stack>
          </Stack>
          <Stack enableScopedSelectors tokens={GapStackTokens}>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/consent' element={<Consent />} />
              <Route path='/product/:pid/:releaseid?' element={<Products />} />
              <Route path='/share/:pid' element={<Shares />} />
              <Route path='/partsFRU' element={<PartsFRU />} />
              <Route path='/faq' element={<FAQ />} />
              <Route path='/frequentlyusedlinks' element={<FreqLinks />} />
              <Route path='/research' element={<Research />} />
              <Route path='/trainingvideos' element={<TrainingVideos />} />
              <Route path='/sftp' element={<SFTP />} />
              <Route path='/whitepapers' element={<WhitePapers />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/terms' element={<TermsOfUse />} />
              <Route path='/session-expired' element={<SessionExpired />} />
              <Route element={<NotFound />} />
            </Routes>
          </Stack>
        </Stack>
        <Stack className='footer' verticalAlign='end'>
          <Separator styles={{ root: { 'padding': '0px' } }} />
          <Stack className='innerFooter' horizontal verticalAlign='center' enableScopedSelectors styles={{ root: { 'justify-content': 'space-between' } }}>
            <Stack horizontal horizontalAlign='start' className='footerTextBlock'>
              <p><Link to='/terms' >Terms of Use</Link>
                <span className='footerDivide'>&nbsp;&nbsp;|&nbsp;&nbsp;</span>© {new Date().getFullYear()} - Meteorcomm LLC
              </p>
            </Stack>
            <Stack horizontalAlign='end'>
              <span><img src={`${process.env.PUBLIC_URL}/img/mLogo.png`} className='mLogo footerLogo' alt='Meteorcomm Logo' /></span>
            </Stack>
          </Stack>
        </Stack>
      </ProductsProvider>
    </ThemeProvider>
  )
}

export default App;